<template> 
	<div class="form-check form-check-inline checkbox">
		<input class="input-style" :name="name" type="checkbox" tabindex="0" v-model="checked" :id="name" @change="checkBoxChanged">
		<label class="label" :for="name" v-html="labelText"></label>
		<div class="datenschutz-help help" v-show="showError">{{ errorMessage }}</div>
	</div>
</template> 

<script>
	export default {
		name: "Checkbox",
		props: {
			initialValue: {
				type: Boolean,
				default: false,
			},
			errorMessage: {
				type: String,
				default: '',
			},
			showError: {
				type: Boolean,
				default: false,
			},
			labelText: {
				type: String,
				default: '',
			},
			name: {
				type: String,
				default: '',
			},
		},
		data() {
			return {
				checked: this.initialValue,
			}
		},
		watch: {
			initialValue(newValue){
				this.checked = this.initialValue;
			}
		},
		methods: {
			checkBoxChanged(origin){
				this.$emit('checkBoxChanged', this.checked);
			},
		},
	}	
</script>

<style lang="scss" scoped>
	.checkbox{
		width: 100%;
		
		label {
			position: relative;
			margin-left: 40px;
			text-align: left;
			cursor: pointer;
			width: 100%;

			&:before{
				content: '';
				position: absolute;
				left: -40px;
				-webkit-appearance: none;
				width: 20px;
				height: 20px;
				border: 1px solid rgba(58, 58, 58, 0.2);
				display: inline-flex;
				margin-right: 20px;
			}

			&:after{
				content: '';
				display: block;
				position: absolute;
				top: 2px;
				left: 9px;
				width: 6px;
				height: 14px;
				border: solid #FFFFFF;
				border-top-width: medium;
				border-right-width: medium;
				border-bottom-width: medium;
				border-left-width: medium;
				border-width: 0 2px 2px 0;
				transform: rotate(45deg);
				left: -33px;
			}

		}

		input[type="checkbox"]{
			opacity: 0;

			&:focus + label::before {
				border: 2px dashed #21B5EA !important;
				border-radius: 3px !important;
				outline: none !important;
			}

			+ label::after {
				content: none;
			}

			&:checked{ 

				+ label::before {
					background-color: #21B5EA;
				}

				+ label::after{
					content: '';
					display: block;
					position: absolute;
					top: 2px;
					left: 9px;
					width: 6px;
					height: 14px;
					border: solid #000;
					border-top-width: medium;
					border-right-width: medium;
					border-bottom-width: medium;
					border-left-width: medium;
					border-width: 0 2px 2px 0;
					transform: rotate(45deg);
					left: -33px;
				}


			}
		}

		.help {
			color: red;
			display: block;
			margin-top: 2px;
		}
	}
</style>
